import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import actions from 'actions/LegalHold/LegalHoldListActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Widget from 'components/Widget/Widget';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row
} from 'reactstrap';
import actionsLC from 'actions/LegalCase/LegalCaseFormActions';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import plusIcon from '../../../../images/icons/plus.svg';
import viewIcon from '../../../../images/icons/eye.svg';
import syncIcon from '../../../../images/icons/sync.svg';
import closeIcon from '../../../../images/icons/close.svg';
import barsIcon from '../../../../images/bars.svg';
import editIcon from '../../../../images/icons/edit.svg';
import trashIcon from '../../../../images/icons/trash.svg';
import { FormattingService } from '../../../../utils/sizeFormatting';
import { toast } from 'react-toastify';
import formActions from 'actions/LegalHold/LegalHoldFormActions';
import LegalHoldFormPage from '../form/LegalHoldFormPage';
import Pagination from 'react-js-pagination';
import styles from './LegalHoldList.module.scss';
import config from 'config';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import LegalHoldSlackFormPage from '../form/slackForm/LegalHoldSlackFormPage';
import { VscOpenPreview } from 'react-icons/vsc';
import chatIcon from 'images/icons/chatIcon.svg';
import mailIcon from 'images/icons/mailIcon.svg';

class LegalHoldListTable extends Component {
  intervalID = 0;
  interval = 0;
  state = {
    modalOpen: false,
    resetModalOpen: false,
    dropdownItem: [],
    isIndexing: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 5,
    newRows: [],
    searchString: '',
    isEditing: false,
    legalHoldId: '',
    sortOrder: '',
    holdsToExport: [],
    openDeleteModal: false,
    openWarningModal: false,
    isNotConfirmed: false,
    openModalFromView: false,
    expandExport: false
  };

  handleIndexing() {
    const { dispatch, currentUser } = this.props;
    const legalHoldId = this.state.cell;
    dispatch(actions.doAllowIndexing(legalHoldId, currentUser)).then(() => {
      this.setState({
        modalOpen: false
      });
    });
  }

  resetQueuedItems() {
    const { dispatch, currentUser } = this.props;
    const legalHoldId = this.state.cell;
    dispatch(actions.doResetQueuedItems(legalHoldId, currentUser)).then(() => {
      this.setState({
        resetModalOpen: false
      });
    });
  }

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') != 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  closeModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  closeResetModal() {
    this.setState({
      resetModalOpen: !this.state.resetModalOpen
    });
  }

  openFormModal() {
    this.props.dispatch(formActions.doOpenConfirm());
  }

  closeFormModal() {
    if (this.props.currentStep === 5 && this.props.legalHoldStats?.isResultValid) {
      this.setState({
        openWarningModal: true
      });
    } else {
      this.setState({
        isEditing: false,
        openWarningModal: false,
        isNotConfirmed: false
      });
      this.props.dispatch(formActions.doCloseConfirm());
    }
  }

  openChatModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenChatLH());
  }

  closeChatModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doCloseChatLH());
  }

  handleViewLegalHold = async (row) => {
    const { dispatch, navigate } = this.props;
    const legalHoldId = row?.legalHoldId;
    if (row?.isChatLegalHold && !row?.legalHoldConfirmed) {
      navigate(`/app/rsmfViewer?legalHoldId=${legalHoldId}`);
    } else if (row?.legalHoldConfirmed) {
      navigate(`/app/LegalHold/${legalHoldId}`);
    } else {
      dispatch(formActions.doSetCurrentStep(5));
      if (row?.legalHoldStatistics) {
        dispatch(formActions.doGetLegalHoldStatistics(row?.legalHoldId));
      } else {
        this.interval = setInterval(() => {
          dispatch(formActions.doGetLegalHoldStatistics(row?.legalHoldId));
        }, 20000);
      }
      this.setState({
        legalHoldId,
        isNotConfirmed: true,
        openModalFromView: true
      });
      this.openFormModal();
    }
  };

  actionFormatter(cell, row) {
    const { navigate, permissions, caseStatus } = this.props;
    let value = Math.floor(
      (row.legalHoldStatistics?.indexedItemsCount / row.legalHoldStatistics?.itemCount) * 100
    );
    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'btn bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu>
          {permissions['LegalUI.LegalHold'] && row.isChatLegalHold && (
            <button
              className='btn bg-transparent text-white first-body-text d-flex align-items-center w-100'
              type='button'
              onClick={() => navigate(`/app/rsmfViewer?legalHoldId=${row.legalHoldId}`)}
            >
              <VscOpenPreview
                style={{
                  fontWeight: 'bold',
                  width: '16px',
                  height: '16px'
                }}
              />
              <p className={'mb-0 ms-2'}>Preview</p>
            </button>
          )}
          {permissions['LegalUI.LegalHold'] && (
            <button
              className='btn bg-transparent text-white first-body-text d-flex align-items-center w-100'
              type='button'
              onClick={() => this.handleViewLegalHold(row)}
            >
              <img src={viewIcon} alt={'plus'} className={'me-2'} />
              <p className={'mb-0'}>Details</p>
            </button>
          )}
          {/* {permissions["LegalUI.LegalHold.Edit"] &&
         caseStatus !== config.caseStatus.Closed ? (
            <button
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100 ${
                value !== 100 && value !== 0 && !Number.isNaN(value)
                  ? "disabled"
                  : ""
              }`}
              disabled={value !== 100 && value !== 0 && !Number.isNaN(value)}
              type="button"
              onClick={() => {
                toast.success(
                  "This action can affect number of items for legal hold. It can also affect content search results.",{ icon: false }
                );
                this.setState({
                  isEditing: true,
                  legalHoldId: cell,
                });
                this.openFormModal();
              }}
            >
              <img
                src={editIcon}
                alt={"edit"}
                width={"16px"}
                height={"16px"}
                className={"me-2 "}
              />
              <p className={"mb-0"}>Edit</p>
            </button>
          ) : null} */}
          {permissions['LegalUI.LegalHold.Index'] &&
            caseStatus !== config.caseStatus.Closed &&
            !row.isChatLegalHold && (
              <button
                className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100`}
                type='button'
                disabled={
                  !row.legalHoldStatistics ||
                  row.legalHoldStatistics?.itemCount === 0 ||
                  row.indexingQueued ||
                  row.isIndexed ||
                  !row.legalHoldConfirmed ||
                  (this.props.legalCase?.restoreInProgress &&
                    !this.props.legalCase?.restoreComplete)
                }
                onClick={() => {
                  this.setState({
                    modalOpen: true,
                    cell,
                    isIndexing: [...this.state.isIndexing, cell]
                  });
                }}
              >
                <img
                  src={syncIcon}
                  alt={'sync'}
                  width={'16px'}
                  height={'16px'}
                  className={'me-2 '}
                />
                <p className={'mb-0'}>Index</p>
              </button>
            )}
          {permissions['LegalUI.LegalHold'] &&
            caseStatus !== config.caseStatus.Closed &&
            !row.isChatLegalHold && (
              <button
                className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100`}
                type='button'
                disabled={!row.indexingQueued || !row.legalHoldConfirmed}
                onClick={() => {
                  this.setState({
                    resetModalOpen: true,
                    cell,
                    isIndexing: [...this.state.isIndexing, cell]
                  });
                }}
              >
                <img
                  src={closeIcon}
                  alt={'sync'}
                  width={'16px'}
                  height={'16px'}
                  className={'me-2 '}
                />
                <p className={'mb-0'}>Reset</p>
              </button>
            )}
          {row?.legalHoldStatistics?.itemCount === 0 &&
            row?.legalHoldStatistics?.isResultValid &&
            row.legalHoldConfirmed &&
            caseStatus !== config.caseStatus.Closed && (
              <button
                className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100 `}
                type='button'
                onClick={() => {
                  this.setState({
                    openDeleteModal: true,
                    legalHoldId: cell
                  });
                }}
              >
                <img
                  src={trashIcon}
                  alt={'sync'}
                  width={'14px'}
                  height={'14px'}
                  className={'me-2 '}
                />
                <p className={'mb-0'}>Delete</p>
              </button>
            )}
          {!row.legalHoldConfirmed &&
            row?.legalHoldStatistics !== null &&
            row?.legalHoldStatistics?.isResultValid &&
            caseStatus !== config.caseStatus.Closed && (
              <button
                className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100 `}
                type='button'
                onClick={() => {
                  this.setState({
                    legalHoldId: cell
                  });
                  this.props
                    .dispatch(
                      formActions.doDiscardLegalHold(
                        row?.legalCaseId,
                        this.props?.currentUser,
                        row?.legalHoldId
                      )
                    )
                    .then(() => {
                      toast.success('Legal Hold discarded', { icon: false });
                      this.props.dispatch(
                        actions.doFetch({
                          legalCaseId: row?.legalCaseId,
                          pageNumber: this.state.pageNumber,
                          pageSize: this.state.pageSize
                        })
                      );
                    });
                }}
              >
                <img
                  src={trashIcon}
                  alt={'sync'}
                  width={'14px'}
                  height={'14px'}
                  className={'me-2 '}
                />
                <p className={'mb-0'}>Discard</p>
              </button>
            )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  refreshTable = () => {
    const { dispatch, navigate, legalCaseId } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(actionsLC.doGetStats(legalCaseId, navigate));
      dispatch(
        actions.doFetch({
          legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch, legalCaseId } = this.props;
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        legalCaseId,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
    window.addEventListener('click', (e) => this.resetDropdowns(e));
    if (this.props.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, legalCaseId } = this.props;
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        actions.doFetch({
          legalCaseId,
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
    if (prevProps.holdsToExport !== this.props.holdsToExport) {
      this.setState({
        holdsToExport: this.props?.holdsToExport
      });
    }
    if (
      prevProps.legalHoldStats !== this.props.legalHoldStats &&
      this.props.legalHoldStats?.isResultValid
    ) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  indexProgressFormatter = (item) => {
    if (!item) {
      return null;
    }
    if (!item.indexedItemsCount) {
      return null;
    }
    const indexedItemsCountPartial = Math.floor((item?.indexedItemsCount / item?.itemCount) * 100);

    const failedItemsCountPartial = Math.floor(
      (item?.finallyFailedItemsCount / item?.itemCount) * 100
    );
    return (
      <Progress multi>
        <Progress
          bar
          color='warning'
          animated
          value={failedItemsCountPartial}
          style={{ position: 'relative' }}
        >
          <span
            style={{
              left: 'calc(100% / 2 - 10px)',
              position: 'absolute'
            }}
          >
            {failedItemsCountPartial} %
          </span>
        </Progress>
        <Progress
          bar
          animated={indexedItemsCountPartial < 100}
          value={indexedItemsCountPartial}
          style={{ position: 'relative' }}
        >
          <span
            style={{
              left: 'calc(100% / 2 - 10px)',
              position: 'absolute'
            }}
          >
            {indexedItemsCountPartial} %
          </span>
        </Progress>
      </Progress>
    );
  };

  itemsOnHoldProgressFormatter = (cell, row) => {
    if (!cell) {
      return null;
    }
    let value = Math.floor((cell / row?.legalHoldStatistics?.itemCount) * 100);
    if (isNaN(value)) {
      value = 0;
    }
    return (
      <Progress animated={value < 100} value={value} style={{ position: 'relative' }}>
        <span
          style={{
            left: 'calc(100% / 2 - 10px)',
            position: 'absolute'
          }}
        >
          {value} %
        </span>
      </Progress>
    );
  };

  handleSearch = () => {
    const { dispatch, legalCaseId } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetch({
        legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      legalHoldName: 'legalholdname',
      legalHoldDescription: 'description',
      creationDate: 'creationdate',
      itemSizeInBytes: 'size',
      itemCount: 'count',
      finallyFailedItemsCount: 'faileditemscount',
      legalHoldConfirmed: 'legalholdconfirmed'
    };
    return SortEnum[sortField];
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  handleDeleteLegalHold = () => {
    const { dispatch, legalCaseId, currentUser } = this.props;
    const filter = {
      legalCaseId,
      searchString: this.state.searchString,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
      sortOrder: this.state.sortOrder
    };
    dispatch(actions.doDelete(this.state.legalHoldId, filter, currentUser)).then(() => {
      this.setState({
        openDeleteModal: false
      });
    });
  };

  getContainerIds = (data) => {
    const containerIds = [];
    data.map((item) => {
      containerIds.push(item.containerId);
    });
    return containerIds;
  };

  formatData = (data) => {
    const custodianIds = [];
    data?.custodians?.map((item) => {
      custodianIds.push(item?.custodianId);
    });
    const containerIds = this.getContainerIds(data?.containers);
    const dataToSubmit = {
      legalHoldName: data.legalHoldName,
      legalHoldDescription: data.legalHoldDescription,
      legalCaseId: data?.legalCaseId,
      custodians: custodianIds,
      externalCustodians: data.externalCustodians,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      subject: data.subject,
      internalOnly: data.internalOnly,
      incoming: data.incoming,
      outgoing: data.outgoing,
      noOtherInternalRecipient: data.noOtherInternalRecipient,
      messageClass: data.noOtherInternalRecipient,
      subjectLine: data.subjectLine,
      attachmentNames: data.attachmentNames,
      attachmentExtensions: data.attachmentExtensions,
      containerIds
    };
    return dataToSubmit;
  };

  handleSaveLegalHold = () => {
    const { dispatch, legalCaseId } = this.props;
    const data = this.state.openModalFromView
      ? this.formatData(this.props.record)
      : this.props.filters?.filter;

    const expireonUser = this.state.openModalFromView
      ? this.props.record?.expireonUser
      : this.props.filters?.expireonUser;
    dispatch(
      formActions.doCreate(
        { ...data, legalHoldId: this.props?.createdLegalHoldId },
        {
          expireonUser: expireonUser
        }
      )
    ).then(() => {
      dispatch(
        actions.doFetch({
          legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      );
      this.setState({
        openWarningModal: false
      });
      dispatch(formActions.doCloseConfirm());
    });
  };

  handleDiscardLegalHold = async () => {
    const { dispatch, legalCaseId } = this.props;
    const legalHoldId = this.props?.createdLegalHoldId;
    await dispatch(
      formActions.doDiscardLegalHold(legalCaseId, this.props?.currentUser, legalHoldId)
    ).then(() => {
      toast.success('Legal Hold discarded', { icon: false });
      dispatch(
        actions.doFetch({
          legalCaseId: legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      );
      this.setState({
        openWarningModal: false
      });
      dispatch(formActions.doCloseConfirm());
    });
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = [
    'legalHoldName',
    'legalHoldDescription',
    'creationDate',
    'itemSizeInBytes',
    'itemCount',
    'finallyFailedItemsCount',
    'legalHoldConfirmed'
  ];

  handleExportCSV = () => {
    const { dispatch, currentUser, legalCaseId } = this.props;
    const url = `${config.externalApi}/legal/api/LegalHold/ExportLegalHolds?`;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch, currentUser, legalCaseId } = this.props;
    const url = `${config.externalApi}/legal/api/LegalHold/ExportToPdf?`;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  showTiles() {
    this.props.dispatch(actions.doHideTable());
  }

  render() {
    const toReadableSizeFormat = new FormattingService();
    const columns = [
      {
        dataField: 'legalHoldName',
        text: 'Hold Name',
        sort: true,
        formatter: (cell, row) => (
          <div className='d-flex align-items-center'>
            <span className='me-2'>
              {row.isChatLegalHold ? (
                <img src={chatIcon} alt='chat' className={styles.chatIcon} />
              ) : (
                <img src={mailIcon} alt='mail' className={styles.mailIcon} />
              )}
            </span>
            <div className={styles.holdListName}>{cell}</div>
          </div>
        )
      },
      {
        dataField: 'legalHoldDescription',
        text: 'Description',
        sort: true,
        formatter: (cell, row) => <div className={styles.holdListDescription}>{cell}</div>
      },
      {
        dataField: 'creationDate',
        text: 'Created',
        formatter: dataFormat.dateTimeFormatter,
        sort: true
      },
      {
        dataField: 'itemSizeInBytes',
        text: 'Volume',
        formatter: (cell, row) =>
          row?.legalHoldStatistics?.isResultValid ? (
            dataFormat.sizeFormatter(row.legalHoldStatistics?.itemSizeInBytes)
          ) : (
            <div className='spinner-border text-light' role='status' />
          ),
        sort: true,
        csvFormatter: (item) => (item !== null ? dataFormat.sizeFormatter(item) : null)
      },
      {
        dataField: 'itemCount',
        text: 'Messages',
        formatter: (cell, row) =>
          row?.legalHoldStatistics?.isResultValid ? (
            toReadableSizeFormat.numberWithCommas(row?.legalHoldStatistics?.itemCount)
          ) : (
            <div className='spinner-border text-light' role='status' />
          ),
        sort: true,
        csvFormatter: (item) => (item !== null ? toReadableSizeFormat.numberWithCommas(item) : null)
      },
      {
        dataField: 'legalHoldStatistics.itemsOnLegalHoldCount',
        text: 'Items on Hold Progress',
        formatter: (cell, row) => {
          return this.itemsOnHoldProgressFormatter.bind(this)(cell, row);
        },
        csvExport: false
      },
      {
        dataField: 'legalHoldStatistics',
        text: 'Indexing Progress',
        formatter: (cell, row) => {
          return this.indexProgressFormatter.bind(this)(cell, row);
        },
        csvExport: false
      },
      {
        dataField: 'finallyFailedItemsCount',
        text: 'Failed Index Items',
        sort: true,
        formatter: (cell, row) => {
          return (
            <div style={{ paddingLeft: '25px' }}>
              {row?.legalHoldStatistics?.finallyFailedItemsCount}
            </div>
          );
        },
        csvFormatter: (item) => (item !== null ? toReadableSizeFormat.numberWithCommas(item) : null)
      },
      {
        dataField: 'legalHoldConfirmed',
        text: 'Legal Hold Confirmed',
        sort: true,
        formatter: (cell) => <div>{cell ? 'Yes' : 'No'}</div>,
        csvFormatter: (cell, row) => (cell ? 'Yes' : 'No')
      },
      {
        dataField: 'legalHoldId',
        text: 'Actions',
        csvExport: false,
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.legalHoldId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.legalHoldId]
              });
            }
          }
        }
      }
    ];

    return (
      <div>
        <Widget style={{ marginTop: '20px' }}>
          <Row>
            <Col sm={12}>
              <ToolkitProvider
                bootstrap4
                keyField='legalHoldId'
                columns={columns}
                data={this.state.newRows}
              >
                {(props) => (
                  <>
                    <Row>
                      <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
                        <div className={'d-flex align-items-center'}>
                          <h3 className={'first-headline-text me-4 mb-0'}>Legal Holds</h3>
                        </div>
                      </Col>
                    </Row>
                    <Row key={'table-part'} className='mb-4'>
                      <Col style={{ marginTop: '8px' }}>
                        <div className={'d-flex align-items-center'}>
                          {this.props.permissions['LegalUI.LegalHold.Create'] &&
                          this.props?.caseStatus !== config.caseStatus.Closed ? (
                            <button
                              className='btn first-body-text d-flex align-items-center me-3'
                              type='button'
                              onClick={() => {
                                this.setState({
                                  isEditing: false,
                                  openWarningModal: false,
                                  isNotConfirmed: false,
                                  openModalFromView: false
                                });
                                this.openFormModal();
                              }}
                            >
                              <img src={plusIcon} alt={'plus'} className={'me-2'} />
                              <p className={'mb-0'}>New mail</p>
                            </button>
                          ) : null}
                          {this.props.permissions['LegalUI.LegalHold.ChatLegalHold'] &&
                          this.props?.caseStatus !== config.caseStatus.Closed ? (
                            <button
                              className='btn first-body-text d-flex align-items-center'
                              type='button'
                              onClick={() => this.openChatModal()}
                            >
                              <img src={plusIcon} alt={'plus'} className={'me-2'} />
                              <p className={'mb-0'}>New chat</p>
                            </button>
                          ) : null}
                        </div>
                      </Col>
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '5px'
                        }}
                      >
                        <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                        <span>
                          <button
                            className='btn fw-semi-bold d-flex align-items-center'
                            type='button'
                            onClick={() => this.showTiles()}
                            style={{ height: '37px', marginRight: '5px' }}
                          >
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </button>
                        </span>
                        <span>
                          <input
                            type='search'
                            placeholder={'Search'}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                this.handleSearch();
                              }
                            }}
                            style={{
                              border: '0.5px solid #0046b1',
                              borderRadius: '3px'
                            }}
                            className={'form-control search-input me-5 w-200'}
                            value={this.state.searchString}
                            onChange={(e) => {
                              this.setState({
                                searchString: e.target.value
                              });
                            }}
                          />
                        </span>
                        <span>
                          <button
                            className='btn search-button-with-icon me-2 ms-10'
                            type='button'
                            onClick={this.handleSearch}
                            style={{ height: '37px', width: '37px' }}
                          >
                            <img
                              title={'search'}
                              alt={'search'}
                              width={14}
                              height={14}
                              src={searchIcon}
                            />
                          </button>
                        </span>
                      </Col>
                    </Row>
                    <div className='table-container'>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover fs-sm custom-table`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: true,
                          cellEdit: false
                        }}
                        onTableChange={this.handleTableChange}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </div>
                    <Row key={'pagination'} className='mt-3'>
                      <Col>
                        <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                      </Col>
                      <Col className='d-flex justify-content-end'>
                        {this.state.totalCount ? (
                          <Pagination
                            totalItemsCount={this.state.totalCount}
                            onChange={this.handlePageChange}
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={this.state.pageSize}
                            pageRangeDisplayed={5}
                            prevPageText={'<'}
                            nextPageText={'>'}
                            firstPageText={'<<'}
                            lastPageText={'>>'}
                            linkClassFirst={styles.paginationNext}
                            linkClassPrev={styles.paginationNext}
                            linkClassNext={styles.paginationNext}
                            linkClassLast={styles.paginationNext}
                            linkClass={styles.pageLink}
                            activeLinkClass={styles.activeLinkClass}
                          />
                        ) : null}
                        <Dropdown
                          isOpen={this.state.paginationModalOpen}
                          toggle={() =>
                            this.setState({
                              paginationModalOpen: !this.state.paginationModalOpen
                            })
                          }
                          className={styles.pageSizeDropdown}
                        >
                          <DropdownToggle className='bg-transparent text-white d-flex'>
                            <p
                              style={{ marginBottom: '3px', marginRight: '2px' }}
                              className='first-body-text'
                            >
                              {this.state.pageSize}
                            </p>
                            <img
                              src={caretDown}
                              alt={'uncollapsed'}
                              width='20px'
                              height='20px'
                              className='ms-2'
                            />
                          </DropdownToggle>
                          <DropdownMenu className='dropdown-position-fixed'>
                            {[5, 10, 25].map((item) => (
                              <DropdownItem
                                className={styles.dropdownItem}
                                key={item}
                                onClick={() => {
                                  this.setState({
                                    pageSize: item,
                                    paginationModalOpen: false,
                                    pageNumber: 1
                                  });
                                }}
                              >
                                {item}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </Widget>
        <Modal
          size='md'
          isOpen={this.state.modalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>Are you sure you want to index this legal hold?</p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn mt-4 first-body-text me-3'
                onClick={() => this.closeModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn mt-4 first-body-text'
                onClick={() => this.handleIndexing()}
              >
                Index
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.resetModalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeResetModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>Are you sure you want to reset queued items?</p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn mt-4 me-3 first-body-text'
                onClick={() => this.closeResetModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn mt-4 first-body-text'
                onClick={() => this.resetQueuedItems()}
              >
                Reset
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='lg'
          isOpen={this.props.openFormModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeFormModal())}>
            <p className='second-headline-text'>
              {this.state.isEditing ? 'Edit Legal Hold' : ' Create Legal Hold'}
            </p>
          </ModalHeader>

          <ModalBody>
            <LegalHoldFormPage
              isEditing={this.state.isEditing}
              legalCaseId={this.props.legalCaseId}
              legalHoldId={this.state.legalHoldId}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
              isNotConfirmed={this.state.isNotConfirmed}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='lg'
          isOpen={this.props.openChatModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeChatModal())}>
            <p className='second-headline-text'>Create Legal Hold</p>
          </ModalHeader>
          <ModalBody>
            <LegalHoldSlackFormPage
              legalCaseId={this.props.legalCaseId}
              legalHoldId={this.state.legalHoldId}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
              currentUser={this.props.currentUser}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openDeleteModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openDeleteModal: false
              })
            )}
          >
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <div className={styles.deleteModalContainer}>
              Are you sure you want to delete this item?
            </div>
            <div className={styles.deleteModalFooter}>
              <button
                className={`${styles.deleteButton} first-body-text btn secondary-btn`}
                type='button'
                onClick={() =>
                  this.setState({
                    openDeleteModal: false
                  })
                }
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={() => this.handleDeleteLegalHold()}
                className={`${styles.deleteButton} first-body-text btn danger-btn`}
              >
                Delete
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openWarningModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openWarningModal: false
              })
            )}
          ></ModalHeader>
          <ModalBody>
            <div className='fw-bold text-center'>
              <p className={styles.warningMessage}>
                Please confirm or discard legal hold before leaving!
              </p>
            </div>
            {this.props.legalHoldStats?.isResultValid &&
              this.props.legalHoldStats?.itemCount !== null && (
                <div className='d-flex justify-content-center mt-4'>
                  <button
                    className='btn danger-btn first-body-text d-flex align-items-center me-3'
                    onClick={() => this.handleDiscardLegalHold()}
                  >
                    Discard
                  </button>
                  <button
                    className='btn first-body-text d-flex align-items-center'
                    onClick={() => this.handleSaveLegalHold()}
                  >
                    Confirm
                  </button>
                </div>
              )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.LegalHold.list.rows,
    permissions: store.auth.permissions,
    count: store.LegalHold.list.count,
    currentUser: store.auth.currentUser,
    openFormModal: store.LegalHold.form.openFormModal,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme,
    legalHoldStats: store.LegalHold.form.legalHoldStats,
    currentStep: store.LegalHold.form.currentStep,
    record: store.LegalHold.form.record,
    filters: store.LegalHold.form.filters,
    legalCase: store.LegalCase.form.record,
    createdLegalHoldId: store.LegalHold.form.legalHoldId,
    openChatModal: store.LegalHold.form.openChatModal
  };
}

export default connect(mapStateToProps)(LegalHoldListTable);
