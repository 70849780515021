import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import { connect } from 'react-redux';
import actions from 'actions/Dashboard/DashboardStatsActions';
import { Col, Row } from 'reactstrap';
import { FormattingService } from 'utils/sizeFormatting';
import ApexChart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import styles from './Dashboard.module.scss';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { emptyDataMessage } from 'actions/common';

class DashboardStatsView extends Component {
  state = {
    casesStatusLabels: [],
    casesStatusSeries: [],
    exportJobsLabels: [],
    exportJobsSeries: [],
    authorizedUsers: [
      {
        name: 'Marek Hurta',
        roles: ['Case Owner: (5 Cases)', 'Auditor: (32 Cases)'],
        lastLogin: '28.08.2024'
      },
      {
        name: 'Daniel Maiworm',
        roles: ['Auditor: (3 Cases)'],
        lastLogin: '28.08.2024'
      }
    ]
  };

  getLegalCasesStatusData = () => {
    const labels = [];
    const series = [];
    this.props?.legalCasesPerStatus?.distributionResult &&
      Object.entries(this.props?.legalCasesPerStatus?.distributionResult).map(([key, value]) => {
        labels.push(key);
        series.push(value);
      });
    this.setState({
      casesStatusLabels: labels,
      casesStatusSeries: series
    });
  };

  getExportJobsStatusData = () => {
    const labels = [];
    const series = [];
    this.props?.exportJobsByIsExported?.distributionResult &&
      Object.entries(this.props?.exportJobsByIsExported?.distributionResult).map(([key, value]) => {
        labels.push(key);
        series.push(value);
      });
    this.setState({
      exportJobsLabels: labels,
      exportJobsSeries: series
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doGetContentSearchesCount());
    dispatch(actions.doGetExportJobsCount());
    dispatch(actions.doGetLegalCasesCount());
    dispatch(actions.doGetLegalHoldsCount());
    dispatch(actions.doGetExportJobsByStatus()).then(() => {
      this.getExportJobsStatusData();
    });
    dispatch(actions.doGetLegalCasesPerStatus()).then(() => {
      this.getLegalCasesStatusData();
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.legalCasesPerStatus !== this.props.legalCasesPerStatus) {
      this.getLegalCasesStatusData();
      this.getExportJobsStatusData();
    }
  }
  render() {
    const toReadableSizeFormat = new FormattingService();
    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: false,
        headerStyle: {
          width: '15%'
        }
      },
      {
        dataField: 'roles',
        text: 'Role',
        sort: false,
        headerStyle: {
          width: '65%'
        },
        formatter: (cell, row) => (
          <div>
            <p>{cell[0]}</p>
            <p>{cell[1]}</p>
          </div>
        )
      },
      {
        dataField: 'lastLogin',
        text: 'Last Login',
        sort: false,
        headerStyle: {
          width: '20%'
        },
        formatter: (cell, row) => <div>{cell}</div>
      }
    ];
    return (
      <div>
        <Row sm={12}>
          <Col sm={4}>
            <Widget className={styles.section}>
              {' '}
              <h4 className={'second-headline-text text-center mb-10'}>Total Legal Cases</h4>
              <div className={'text-center'}>
                <h3 className={`${styles.widgetBorder} first-subtitle-text`}>
                  {this.props.legalCasesCount}
                </h3>
              </div>
              <div className={styles.statsWrapper}>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Total Items Count:</span> <span>2789</span>
                </p>
                <p className={styles.stats}>
                  {' '}
                  <span className={styles.statsLabel}>Total Items Size:</span>
                  <span>13GB</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Items with Attachments:</span>
                  <span>1300</span>
                </p>
              </div>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget className={`${styles.legalCaseStatusChart} ${styles.section}`}>
              <h4 className={'second-headline-text'}> Legal Cases Status</h4>
              <div className={'first-caption-text'}>
                <ApexChart
                  type='donut'
                  style={{ color: 'white' }}
                  series={this.state.casesStatusSeries || []}
                  height={150}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '75%',
                          labels: {
                            show: false
                          }
                        }
                      }
                    },
                    labels: this.state.casesStatusLabels || [],
                    legend: {
                      color: 'white'
                    },
                    stroke: {
                      width: 0
                    },
                    chart: {
                      foreColor: '#fff',
                      fontFamily: 'Poppins'
                    }
                  }}
                />
              </div>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget
              title={<h4 className={'mb-2 text-start first-headline-text'}>Authorized Users</h4>}
              className={`${styles.section}`}
            >
              <div>
                <ToolkitProvider
                  bootstrap4
                  columns={columns}
                  data={this.state.authorizedUsers}
                  keyField='name'
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover mt-4 fs-sm`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: false,
                          cellEdit: false
                        }}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Widget>
          </Col>
        </Row>
        <Row sm={12}>
          <Col sm={4}>
            <Widget className={styles.section}>
              {' '}
              <h4 className={'second-headline-text text-center mb-10'}>Total Legal Holds</h4>
              <div className={'text-center'}>
                <h3 className={`${styles.widgetBorder} first-subtitle-text d-inline-block`}>
                  {toReadableSizeFormat.numberWithCommas(this.props.legalHoldsCount || 0)}
                </h3>
              </div>
              <div className={styles.statsWrapper}>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Mail Legal Holds:</span>
                  <span>9</span>{' '}
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Chat Legal Holds:</span>
                  <span>4</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Indexed Items:</span>
                  <span>10</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Index Failures:</span>
                  <span>2</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Confirmed Legal Holds:</span>
                  <span>12</span>
                </p>
              </div>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget className={styles.section}>
              <h4 className={'second-headline-text text-center mb-10'}>Total Content Searches</h4>
              <div className={'text-center mt-3'}>
                <h3 className={`${styles.widgetBorder} first-subtitle-text d-inline-block`}>
                  {toReadableSizeFormat.numberWithCommas(this.props.contentSearchesCount || 0)}
                </h3>
              </div>
              <div className={styles.statsWrapper}>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Total Items Count:</span>
                  <span>2789</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Total Items Size:</span>
                  <span>13GB</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Items with Attachments:</span>
                  <span>1300</span>
                </p>
              </div>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget className={styles.section}>
              <h4 className={'second-headline-text text-center mb-10'}>Total Export Jobs</h4>
              <div className={'text-center mt-3'}>
                <h3 className={`${styles.widgetBorder} first-subtitle-text d-inline-block`}>
                  {toReadableSizeFormat.numberWithCommas(this.props.exportJobsCount || 0)}
                </h3>
              </div>
              <div className={styles.statsWrapper}>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Exported Content Searches:</span>
                  <span>53</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Exported Legal Holds:</span>
                  <span>22</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Total Volume:</span>
                  <span>13GB</span>
                </p>
                <p className={styles.stats}>
                  <span className={styles.statsLabel}>Total Items:</span>
                  <span>2567</span>
                </p>
              </div>
            </Widget>
          </Col>
        </Row>
        <Row sm={12}>
          <Col sm={8}>
            {' '}
            <Widget className={`${styles.largeSection}`}>
              {' '}
              <ReactApexChart
                style={{ color: 'white' }}
                options={{
                  chart: {
                    type: 'bar',
                    height: 370,
                    toolbar: {
                      show: false
                    }
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    }
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: false,
                    width: 2
                  },
                  legend: {
                    labels: {
                      colors: '#ffffff' // Set legend text to white
                    },
                    position: 'bottom', // Position the legend at the bottom
                    horizontalAlign: 'center', // Align the legend horizontally
                    offsetY: 10
                  },

                  xaxis: {
                    categories: [
                      'Jan',
                      'Feb',
                      'Mar',
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec'
                    ],
                    labels: {
                      style: {
                        colors: '#ffffff' // Set x-axis labels to white
                      }
                    }
                  },
                  yaxis: {
                    title: {
                      text: 'Items'
                    },
                    labels: {
                      style: {
                        colors: '#ffffff' // Set x-axis labels to white
                      }
                    }
                  },

                  fill: {
                    opacity: 1
                  },

                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return +val + 'items';
                      }
                    }
                  }
                }}
                series={[
                  {
                    name: 'Exported Content Searches',
                    data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 57, 39, 59]
                  },
                  {
                    name: 'Exported Legal Holds',
                    data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 115, 69, 37]
                  }
                ]}
                type='bar'
                height={350}
              />
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget className={`${styles.exportStatusChart} ${styles.largeSection}`}>
              <h4 className='second-headline-text'> Export Status</h4>
              <div className={'first-caption-text mb-0'}>
                <ApexChart
                  type='donut'
                  series={this.state.exportJobsSeries || []}
                  height={150}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '75%',
                          labels: {
                            show: false
                          }
                        }
                      }
                    },
                    labels: this.state.exportJobsLabels,
                    stroke: {
                      width: 0
                    },
                    chart: {
                      foreColor: '#fff',
                      fontFamily: 'Poppins'
                    }
                  }}
                />
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    legalCasesCount: store.Dashboard.stats.legalCasesCount,
    legalHoldsCount: store.Dashboard.stats.legalHoldsCount,
    contentSearchesCount: store.Dashboard.stats.contentSearchesCount,
    exportJobsCount: store.Dashboard.stats.exportJobsCount,
    legalCasesPerStatus: store.Dashboard.stats.legalCasesPerStatus,
    exportJobsByIsExported: store.Dashboard.stats.exportJobsByIsExported
  };
}

export default connect(mapStateToProps)(DashboardStatsView);
